$(document).ready(function() {
    $('.product .gallery').slick({
        arrows: false,
    });
});

$(document).ready(function() {
    $('.home__slides').slick({
        autoplay: true,
        autoplaySpeed: 2400,
        speed: 600,
        fade: true,
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,

    });

    if (isTouchDevice()) {
        $('.gallery__modal .gallery__images').slick({
            autoplay: false,
            speed: 600,
            arrows: false,
            lazyLoad: 'ondemand',
        });
    }
    else {
        $('.gallery__modal .gallery__images').slick({
            autoplay: false,
            speed: 600,
            prevArrow: '<button type="button" class="btn btn-default previous" aria-label="Previous"><span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span></button>',
            nextArrow: '<button type="button" class="btn btn-default next" aria-label="Next"><span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span></button>',
            lazyLoad: 'ondemand',
        });
    }
});

$(document).on("click", ".gallery__thumbs > a", function(e) {
    e.preventDefault();
    var slide = $(this).attr('data-slide');
    var slider = $('.product .gallery');
    slider[0].slick.slickGoTo(parseInt(slide - 1));
});

jQuery(document).ready(function($) {
    $('.page--gallery--list .gallery__container .row').isotope({
        itemSelector: '.gallery__image',
        percentPosition: true,
        layoutMode: 'masonry'
    });
});

$(document).on('click', '.gallery__modal__close', function(event) {
    event.preventDefault();
    $('.gallery__modal').fadeOut();
     $('body').removeClass('no-scroll');
     $('#viewport').attr('content', 'width=device-width, initial-scale=1.0');
});


$(document).on("click", ".gallery__image a", function(e) {
    e.preventDefault();
    var slide = $(this).attr('data-slide');
    $('.gallery__modal').fadeIn();
    $('#viewport').attr('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    $('body').addClass('no-scroll');
    var slider = $('.gallery__modal .gallery__images');
    slider[0].slick.slickGoTo(parseInt(slide - 1));
});

$(document).on("click", ".product__single .gallery .image__container", function(e) {
    e.preventDefault();
    var slide = $(this).attr('data-slide');
    $('.gallery__modal').fadeIn();
    $('#viewport').attr('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
     $('body').addClass('no-scroll');
    var slider = $('.gallery__modal .gallery__images');
    slider[0].slick.slickGoTo(parseInt(slide - 1));
});
