function pa_resize_ibars() {
    var difference = Math.ceil(parseInt($('main').css('padding-top')) + $('#header').outerHeight() + parseInt($('#header').css('margin-bottom')));
    var inHeight = window.innerHeight;
    if(inHeight < 320) {
      inHeight = 320;
    }
    $('.home__slide, .nav__mobile').height(inHeight - difference + 2);
}
if (isMobile.any) {
    pa_resize_ibars();
    $(window).resize(function(event) {
        pa_resize_ibars();
    });
}

