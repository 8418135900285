$(document).on("click", ".nav__mobile__toggle:not(.active)", function(e) {
	e.preventDefault();
	$('body').append('<div class="nav__mobile" />');
	var nav = $('.nav__mobile').append($('#nav').html());
	$(this).addClass('active');
	setTimeout(function() {
		$('.nav__mobile').addClass('active');
	}, 50);
}).on("click", ".nav__mobile__toggle.active", function(e) {
	e.preventDefault();
	$('.nav__mobile').removeClass('active');
	$(this).removeClass('active');
	setTimeout(function() {
		$('.nav__mobile').remove();
	}, 400);
});