$(document).ready(function() {
    $('.match-heights').matchHeight();
    if (isTouchDevice() === false) {
        $('[data-toggle="tooltip"]').tooltip();
    }
});

function isTouchDevice() {
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}
